<template>
  <div>
    <el-dialog
      title="开药"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <div>
        <div class="medBox">
          <h3>确定药品信息</h3>

          <div class="everyRaw">
            <h4>添加药品名称</h4>
            <div class="checkBoxItem">
              <el-input
                v-model="searchDrugName"
                placeholder="请添加药品内容"
                style="width: 273px"
                clearable
              ></el-input>
              <el-button
                @click="searchDrugFuc"
                style="
                  margin: auto 10px 0;
                  background: #31b4b0;
                  border-radius: 4px;
                  color: #fff;
                  display: inline-block;
                "
                >药房检索</el-button
              >
            </div>
          </div>

          <div class="everyRaw">
            <h4></h4>
            <div class="grayInfo">
              已选择药品{{ choosenDrugList.length || 0 }}件
            </div>
          </div>
        </div>

        <!-- 检索结果 -->
        <div class="medBox">
          <h3>检索结果</h3>

          <el-table :data="queryDrugList" fit size="small">
            <el-table-column prop="medicalName" label="药品通用名">
            </el-table-column>
            <el-table-column prop="dosageFormName" label="剂型">
            </el-table-column>
            <el-table-column prop="productionName" label="厂家">
            </el-table-column>
            <el-table-column prop="specsPacking" label="规格">
            </el-table-column>
            <el-table-column prop="retailPrice" label="售价"> </el-table-column>
            <el-table-column prop="medicalType" label="药品类型">
            </el-table-column>
            <el-table-column label="操作" v-slot="scope">
              <el-button size="small" @click="addThisLine(scope)"
                >添加</el-button
              >
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            :total="queryDrugListTotal"
            :current-page="pageIndex"
            @current-change="searchDrugFuc"
            v-if="queryDrugListTotal > 1"
          ></el-pagination>
        </div>
        <div class="grayInfo, fixMargin">可选药品清单（单处方限5种药品）</div>

        <div class="medBox">
          <h3>选择结果</h3>
          <el-table :data="choosenDrugList" size="small">
            <el-table-column
              prop="medicalName"
              label="药品通用名"
            ></el-table-column>
            <el-table-column prop="specsPacking" label="规格"></el-table-column>
            <el-table-column
              prop="retailPrice"
              label="售价"
              width="50"
            ></el-table-column>
            <!-- <el-table-column
              prop="quantity"
              label="数量"
              v-slot="scope"
              width="50"
            >
              <el-input v-model="scope.row.quantity" />
            </el-table-column> -->
            <el-table-column prop="quantity" label="数量" v-slot="scope">
              <el-input v-model="scope.row.quantity" />
            </el-table-column>
            <el-table-column
              prop="minPackingUnit"
              label="包装单位"
              width="50"
            ></el-table-column>

            <el-table-column prop="medicalUsage" label="用法" v-slot="scope">
              <el-cascader
                :props="propsOptions"
                :options="ROUTE"
                v-model="scope.row.medicalUsage"
                placeholder="用法"
              />
            </el-table-column>

            <el-table-column prop="consumption" label="用量" v-slot="scope">
              <el-input v-model="scope.row.consumption" />
            </el-table-column>
            <el-table-column
              prop="minUseUnit"
              label="最小使用单位"
              v-slot="scope"
            >
              <el-select
                v-model="scope.row.minUseUnit"
                filterable
                clearable
                placeholder="最小使用单位"
                @change="changeMinUseUnitCode(scope)"
              >
                <el-option
                  v-for="item in MINUSEUNIT"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-table-column>
            <!-- <el-table-column
                        prop="minUseUnit"
                        label="计量单位"
                        width="50"
                    ></el-table-column> -->
            <el-table-column prop="frequencyCode" label="频次" v-slot="scope">
              <el-select
                v-model="scope.row.frequencyCode"
                filterable
                clearable
                placeholder="频次"
                @change="changeFrequencyCode(scope)"
              >
                <el-option
                  v-for="item in FREQUECY"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-table-column>
            <el-table-column
              prop="medicationDays"
              label="用药天数"
              v-slot="scope"
            >
              <el-input v-model="scope.row.medicationDays" />
            </el-table-column>
            <el-table-column prop="entrust" label="嘱托" v-slot="scope">
              <el-input
                v-model="scope.row.entrust"
                type="textarea"
                maxlength="20"
              />
            </el-table-column>

            <el-table-column label="操作" v-slot="scope">
              <el-button size="small" @click="handleDelete(scope)"
                >删除</el-button
              >
            </el-table-column>
          </el-table>
        </div>
        <div class="grayInfo, fixMargin">
          已选择药品{{ choosenDrugList.length || 0 }}件,还可添加{{
            5 - choosenDrugList.length
          }}件药品
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确认开药</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { medicalPageQuery, queryList } from '../../api/doctor';
// import editableCell from '@/components/EditableCell';
export default {
  data() {
    return {
      propsOptions: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const { code } = node.data;
          const nodes = await queryList({ dicTypeCode: code });
          console.log(nodes, 'queryListqueryList');
          nodes.data.data.forEach((element) => {
            element.leaf = true;
          });
          resolve(nodes.data.data);
        },
        value: 'name',
        label: 'name',
      },
      dialogVisible: false,
      searchDrugName: '',
      choosenDrugList: [], // 已选择药品
      queryDrugList: [], // 接口返回的药品
      queryDrugListTotal: 0, // 接口返回的药品数量
      pageIndex: 1, // 当前请求页数
    };
  },
  // components: { editableCell },
  watch: {
    searchDrugName(val) {
      this.queryDrugList = [];
      this.queryDrugListTotal = 1;
      this.pageIndex = 1;
    },
    isShow(val) {
      console.log(val, 'isShow');
      if (val) {
        this.searchDrugName = ''; // 搜索药品名
        this.choosenDrugList = []; // 已选择药品
        this.queryDrugList = []; // 接口返回的药品
        this.queryDrugListTotal = 0; // 接口返回的药品数量
        this.pageIndex = 1; // 当前请求页数
        this.dialogVisible = true;
      } else {
        this.dialogVisible = false;
      }
    },
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    ROUTE: {
      type: Array,
      default: () => [],
    },
    FREQUECY: {
      type: Array,
      default: () => [],
    },
    MINUSEUNIT: {
      type: Array,
      default: () => [],
    },
    interFaceDrugList: {
      type: Array,
      default: () => [],
    },
    reEditFlag: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    submit() {
      const check = [
        'consumption',
        'frequency',
        'frequencyCode',
        'medicalUsage',
        'medicationDays',
        'quantity',
        // 'entrust',
      ];
      let flag = false;
      if (!this.choosenDrugList.length) {
        this.$message({
          message: '未选择药品！',
          type: 'error',
        });
        return;
      }

      this.choosenDrugList.map((i) => {
        check.forEach((element) => {
          if (i[element] === '') flag = true;
        });
      });
      if (flag) {
        this.$message({
          message: '药品缺少必填项',
          type: 'error',
        });
        return;
      }

      this.$emit('submit', this.choosenDrugList);
    },
    addThisLine(value) {
      if (this.choosenDrugList.length > 4) {
        this.$message({
          message: '所选药品大于五种！',
          type: 'error',
        });
        return;
      }

      const allready = this.choosenDrugList.find((i) => i.id === value.row.id);
      if (allready)
        return this.$message({
          message: '该药品已选择',
          type: 'warning',
        });
      value.row.consumption = '';
      value.row.frequency = '';
      value.row.frequencyCode = '';
      value.row.medicalUsage = '';
      value.row.medicationDays = '';
      value.row.quantity = '';
      value.row.entrust = '';
      console.log(value, 'value');
      this.choosenDrugList.push({
        ...value.row,
      });
      // this.$set(
      //   this.choosenDrugList,
      //   this.choosenDrugList.length ? this.choosenDrugList.length - 1 : 0,
      //   value.row
      // );
    },
    handleClose(done) {
      // this.$confirm('确认关闭？')
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
      this.$emit('close');
    },

    async searchDrugFuc(value) {
      const { appointment, consultationOrderNo, orderNo, resource } =
        this.custom;
      if (typeof value === 'number') this.pageIndex = value;
      this.queryDrugList = [];
      const params = {
        medicalName: this.searchDrugName,
        thirdPartyOrderNo: appointment
          ? appointment.thirdPartyOrderNo
          : resource.thirdPartyOrderNo,
        consultationOrderNo,
        orderNo,
        page: {
          pageIndex: this.pageIndex,
          pageSize: 10,
        },
      };
      const res = await medicalPageQuery(params).catch((error) => {
        console.error(error);
      });
      console.log(res.data.data.total, 'res.data.data.');
      this.queryDrugList = res.data.data.data;
      this.queryDrugListTotal = Number(res.data.data.total);
    },

    handleDelete(scope) {
      this.choosenDrugList.splice(scope.$index, 1);
    },
    changeFrequencyCode(scope) {
      const { row } = scope;
      const name = this.FREQUECY.find((i) => i.code === row.frequencyCode).name;
      row.frequency = name;
    },
    changeMinUseUnitCode(scope) {
      const { row } = scope;
      const name = this.MINUSEUNIT.find((i) => i.code === row.minUseUnit).name;
      row.minUseUnit = name;
    },

    //     const changeFrequencyCode = (scope) => {
    //   const { row } = scope;
    //   const name = props.FREQUECY.find((i) => i.code === row.frequencyCode).name;
    //   row.frequency = name;
    // };
    // const changeMinUseUnitCode = (scope) => {
    //   const { row } = scope;
    //   const name = props.MINUSEUNIT.find((i) => i.code === row.minUseUnit).name;
    //   row.minUseUnit = name;
    // };
  },
};
</script>

<style lang="scss" scoped>
.medBox {
  margin: 0 0px 17px;
  padding-bottom: 30px;
  border: 1px solid #e7e7e7;
  h3 {
    height: 40px;
    background: #fbfbfb;
    line-height: 40px;
    // padding: 0 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 15px;
    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .everyRaw {
    display: flex;
    align-items: center;
    margin-top: 20px;

    h4 {
      margin: 0;
      display: inline-block;
      width: 110px;
      text-align: right;
      margin-right: 14px;
      color: #666666;
      font-size: 14px;
    }
    .checkBoxItem {
      width: 100%;
      :global {
        .el-checkbox {
          width: 150px;
        }
      }
    }
  }
}
.borderBox {
  border: 1px solid #e5e5e5;
  height: 44px;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.grayInfo {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999 !important;
  margin-left: -15px;
}
.fixMargin {
  margin: -6px 0 24px;
}
</style>
