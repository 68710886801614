<template>
  <div>
    <div class="handlerWraper">
      <div class="userInfo box">
        <el-collapse v-model="activeNames1">
          <el-collapse-item title="患者信息" name="1">
            <div class="formBody">
              <div class="boxCard">
                <ul>
                  <li
                    v-for="(item, index) in fPatientInfo"
                    :key="index"
                    class="text item"
                  >
                    <span class="label">{{ item.label }}：</span>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.value"
                      placement="top-start"
                      v-if="item.value.length > 8"
                    >
                      <span class="value">{{
                        item.value.slice(0, 8) + '...'
                      }}</span>
                    </el-tooltip>
                    <span v-else class="value">{{ item.value }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="box dataList" v-if="patientInfo.consultationImageUrl">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="就诊文件" name="1">
            <ul>
              <li
                v-for="(item, index) in patientInfo.consultationImageUrl.split(
                  ','
                )"
                :key="item.id"
                style="margin: 0 0 4px 4px"
              >
                <span class="fileName" :href="item" target="_blank">
                  文件{{ index + 1 }}
                </span>
                <el-image
                  style="width: 40px; height: 40px"
                  :src="item"
                  :preview-src-list="
                    patientInfo.consultationImageUrl.split(',')
                  "
                  fit="cover"
                >
                </el-image>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>

      <div class="box">
        <h3>诊断报告</h3>
        <div
          class="judgeForm"
          v-if="custom.summaryDetail && custom.summaryDetail.signaturePdfImgUrl"
        >
          <div>
            <div>病历:</div>
            <el-image
              style="width: 50%; height: 200px"
              :src="custom.summaryDetail.signaturePdfImgUrl"
              :preview-src-list="
                custom.summaryDetail.signaturePdfImgUrl.split(',')
              "
              fit="scale-down"
            >
            </el-image>
            <el-divider></el-divider>
          </div>
        </div>

        <el-form
          label-width="90px"
          :model="medicalRecord"
          :disabled="!!custom.summaryDetail"
          v-else
        >
          <el-form-item label="主诉" prop="chiefComplaint">
            <el-input
              type="textarea"
              maxlength="500"
              v-model="medicalRecord.chiefComplaint"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="现病史" prop="presentIllnessHistory">
            <el-input
              type="textarea"
              v-model="medicalRecord.presentIllnessHistory"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="现住址" prop="presentAddress">
            <el-input
              type="textarea"
              v-model="medicalRecord.presentAddress"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="既往史" prop="pastHistory">
            <el-input
              type="textarea"
              v-model="medicalRecord.pastHistory"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="药物过敏史" prop="drugAllergicHistory">
            <el-input
              type="textarea"
              v-model="medicalRecord.drugAllergicHistory"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="其它过敏史" prop="allergicHistory">
            <el-input
              type="textarea"
              v-model="medicalRecord.allergicHistory"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="个人史" prop="personalHistory">
            <el-input
              type="textarea"
              v-model="medicalRecord.personalHistory"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="家族史" prop="familyHistory">
            <el-input
              type="textarea"
              v-model="medicalRecord.familyHistory"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="查体" prop="physicalExamination">
            <el-input
              type="textarea"
              v-model="medicalRecord.physicalExamination"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="辅助检查" prop="supplementaryExamination">
            <el-input
              type="textarea"
              v-model="medicalRecord.supplementaryExamination"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
          <el-form-item label="初步诊断" prop="diagnosisName">
            <el-select
              multiple
              filterable
              remote
              reserve-keyword
              v-model="medicalRecord.diagnosisArray"
              :remote-method="remoteMethod"
              :loading="loading"
              clearable
              placeholder="初步诊断"
              value-key="name"
              @change="seechange"
            >
              <el-option
                v-for="item in options"
                :key="item.code"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="处理建议" prop="handlingSuggestions">
            <el-input
              type="textarea"
              v-model="medicalRecord.handlingSuggestions"
              maxlength="500"
              autosize
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          class="judgeForm"
          v-if="
            custom.prescriptionDetailList &&
            custom.prescriptionDetailList[0] &&
            custom.prescriptionDetailList[0].signaturePdfImgUrl
          "
        >
          <div>处方:</div>
          <el-image
            style="width: 50%; height: 200px"
            :src="custom.prescriptionDetailList[0].signaturePdfImgUrl"
            :preview-src-list="
              custom.prescriptionDetailList &&
              custom.prescriptionDetailList[0] &&
              custom.prescriptionDetailList[0].signaturePdfImgUrl.split(',')
            "
            fit="scale-down"
          >
          </el-image>
        </div>
        <div class="btns">
          <el-button
            @click="onSubmit"
            v-if="!!!custom.summaryDetail"
            type="primary"
            >确认提交</el-button
          >

          <el-button
            v-if="!custom.prescriptionDetailList"
            type="primary"
            :disabled="
              !custom.summaryDetail ||
              !custom.summaryDetail.diagnosisName ||
              !patientInfo.isRevisited
            "
            @click="medicalRecord.recipeFlag = true"
            >开药</el-button
          >

          <!-- :disabled="
              !custom.summaryDetail ||
              !custom.summaryDetail.diagnosisName ||
              !patientInfo.isRevisited
            " -->
          <!-- <el-button
            style="
              background: #31b4b0;
              border-radius: 4px;
              color: #fff;
              display: block;
            "
            v-if="
              custom.prescriptionDetailList &&
              custom.prescriptionDetailList[0] &&
              !custom.prescriptionDetailList[0].signaturePdfImgUrl
            "
            @click="showThePrescription = true"
            >处方单</el-button
          > -->
          <!-- <el-button
            style="
              background: #31b4b0;
              border-radius: 4px;
              color: #fff;
              display: block;
            "
            v-if="!custom.prescriptionDetailList"
            @click="medicalRecord.recipeFlag = true"
            :disabled="
              !custom.summaryDetail ||
              !custom.summaryDetail.diagnosisName ||
              !patientInfo.isRevisited
            "
            >开药</el-button
          > -->
        </div>
      </div>
    </div>

    <Medicine
      :isShow="medicalRecord.recipeFlag"
      :FREQUECY="FREQUECY"
      :MINUSEUNIT="MINUSEUNIT"
      :ROUTE="ROUTE"
      :reEditFlag="reEditFlag"
      :interFaceDrugList="
        custom.prescriptionDetailList &&
        custom.prescriptionDetailList[0] &&
        custom.prescriptionDetailList[0].medicals
      "
      @close="medicalRecord.recipeFlag = false"
      @submit="medicineSubmit"
      :custom="custom"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';

import {
  queryList,
  summaryCreate,
  medicalCreate,
  medicalPageQuery,
} from '../../api/doctor';
import Medicine from './Medicine';
const DICTIONARIES = {
  patientName: '姓名',
  patientSex: '性别', // 0未知的性别 1男 2女 9未说明的性别
  patientAge: '年龄',
  deptName: '科别',
  consultationContent: '问诊主诉',
  // consultationImageUrl: '图片资料',
  // : '预约时间',
  isRevisited: '是否复诊',
  visitingHospital: '线下就诊医院',
  emergencyContactName: '紧急联系人',
  emergencyContactMobile: '紧急联系人电话',
  presentAddress: '现住址',
  legalGuardianName: '监护人姓名',
  legalGuardianRelation: '与监护人关系',
  legalGuardianMobile: '监护人电话',
};

export default {
  data() {
    return {
      activeNames1: ['1'],
      activeNames: ['1'],
      FREQUECY: [], //用药频次
      MINUSEUNIT: [], //y药品
      ROUTE: [], //药品使用途径
      reEditFlag: false, // 是否重新开药
      options: [],

      loading: false,
      fPatientInfo: [],
      patientInfo: {},
      medicalRecord: {
        chiefComplaint: '', // 主诉
        presentIllnessHistory: '', // 现病史
        presentAddress: '', // 现住址
        pastHistory: '', // 既往史
        drugAllergicHistory: '无相关过敏史', // 药物过敏史
        allergicHistory: '无相关过敏史', // 过敏史
        personalHistory: '', // 个人史
        familyHistory: '', // 家族史
        physicalExamination: '线上问诊无法查体', // 查体
        supplementaryExamination: '线上问诊暂无辅助检查', // 辅助检查
        diagnosisArray: [], //诊断名称和代码
        diagnosisName: '', // 诊断名称
        diagnosisCode: '', // 诊断代码
        handlingSuggestions: '', // 处理建议
        recipeFlag: false, // 是否开药
        consultationOrderNo: '',
        // doctorId: '',
      },
    };
  },

  components: {
    Medicine,
  },
  computed: {
    ...mapState({
      custom: (state) => state.patient.custom,
    }),
    // patientInfo() {
    //   return appointment || resource;
    // },
    // fPatientInfo() {
    //   return this.handleShowArray();
    // },
  },
  watch: {
    custom(val) {
      // this.fPatientInfo = this.handleShowArray();
      const {
        appointment,
        resource,

        summaryDetail,
        consultationOrderNo,
      } = this.custom;
      this.patientInfo = appointment || resource;

      this.handleShowArray();

      this.handleSmmaryDetail(summaryDetail);
      this.medicalRecord.chiefComplaint = this.patientInfo.consultationContent;
      this.medicalRecord.consultationOrderNo = consultationOrderNo;
    },
  },
  async mounted() {
    const fData = await queryList({
      dicTypeCode: 'Frequency_Drug_Use_01',
    });

    this.FREQUECY = fData.data.data;

    const mData = await queryList({
      dicTypeCode: 'Medical_Min_Use_Uint',
    });
    this.MINUSEUNIT = mData.data.data;

    const rData = await queryList({
      dicTypeCode: 'Route_Medication_01',
    });
    this.ROUTE = rData.data.data;
  },
  methods: {
    async medicineSubmit(data) {
      const { orderNo } = this.custom;
      data.forEach((i) => {
        const data = i.medicalUsage[1];
        i.medicalUsage = data;
      });
      const params = {
        orderNo,
        longMedicalFlag: 3,
        mobilityFlag: 3,
        medicals: data,
      };
      await medicalCreate(params);
      this.$store.dispatch('updateCustom');
      this.medicalRecord.recipeFlag = false;
    },
    remoteMethod: debounce(async function (data) {
      if (!data) return (this.options = []);
      const searchData = {
        dicTypeCode: 'Case_Category_01',
        name: data,
      };
      const queryData = await queryList(searchData);

      this.options = queryData.data.data;
    }, 1000),
    seechange(data) {
      console.log(data);
    },
    async onSubmit() {
      if (this.custom.consultationStatus == 'WAIT_FOR_SERVER') {
        return this.$message.error('该单尚未接诊，请先接诊');
      }

      for (let key in this.medicalRecord) {
        if (
          !this.medicalRecord[key] &&
          !['diagnosisName', 'diagnosisCode', 'recipeFlag'].includes(key)
        ) {
          console.log(key, 9999);
          return this.$message.error('缺少必填项');
        }
      }

      const diagnosisName = this.medicalRecord.diagnosisArray
        .map((i) => i.name)
        .join(',');
      const diagnosisCode = this.medicalRecord.diagnosisArray
        .map((i) => i.code)
        .join(',');

      const { trustOpenId, trustSignatureUrl } = JSON.parse(
        sessionStorage.getItem('loginDoctorDetail')
      );

      const {
        patientId,
        patientPhoto,
        patientSex,
        patientAge,
        patientMobile,
        patientIdCard,
        patientName,
      } = this.custom.appointment || this.custom.resource;
      let doctor = {
        doctorId: this.custom.doctorId,
      };
      if (this.custom.doctor) {
        doctor = {
          ...this.custom.doctor,
        };
      }

      console.log(doctor, 9999999999);
      const params = {
        ...this.medicalRecord,
        ...doctor,
        patientName,
        patientId,
        patientPhoto,
        patientSex,
        patientAge,
        patientMobile,
        patientIdCard,
        visitDate: this.custom.createTime,
        diagnosisName, // 诊断名称
        diagnosisCode, // 诊断代码
        trustOpenId,
        trustSignatureUrl,
        consultationType: this.custom.consultationType,
      };
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      const res = await summaryCreate(params);
      loading.close();
      const { code, msg } = res.data;

      if (code != 0) {
        return this.$message.error(msg);
      }
      this.$message({
        message: '保存成功',
        type: 'success',
      });
      this.$store.dispatch('updateCustom');
    },
    handleSmmaryDetail(summaryDetail) {
      if (summaryDetail && Object.keys(summaryDetail).length) {
        Object.keys(this.medicalRecord).map((i) => {
          this.medicalRecord[i] = summaryDetail[i];
        });
        const diagnosisArray = [];
        const diagnosisCode = this.medicalRecord?.diagnosisCode?.split(',');
        this.medicalRecord?.diagnosisName?.split(',').map((i, j) => {
          diagnosisArray.push({
            name: i,
            label: i,
            code: diagnosisCode[j],
            value: diagnosisCode[j],
          });
        });

        this.medicalRecord.diagnosisArray = diagnosisArray;
      } else {
        this.medicalRecord = {
          chiefComplaint: '', // 主诉
          presentIllnessHistory: '', // 现病史
          presentAddress: '', // 现住址
          pastHistory: '', // 既往史
          drugAllergicHistory: '无相关过敏史', // 药物过敏史
          allergicHistory: '无相关过敏史', // 过敏史
          personalHistory: '', // 个人史
          familyHistory: '', // 家族史
          physicalExamination: '线上问诊无法查体', // 查体
          supplementaryExamination: '线上问诊暂无辅助检查', // 辅助检查
          diagnosisArray: [], //诊断名称和代码
          diagnosisName: '', // 诊断名称
          diagnosisCode: '', // 诊断代码
          handlingSuggestions: '', // 处理建议
          recipeFlag: false, // 是否开药
          consultationOrderNo: '',
          // doctorId: '',
        };
      }
    },
    handleShowArray() {
      this.fPatientInfo = Object.keys(DICTIONARIES)
        .map((item) => {
          switch (item) {
            case 'patientSex':
              if (this.patientInfo[item]) {
                return {
                  label: DICTIONARIES[item],
                  value: this.patientInfo[item] === '1' ? '男' : '女',
                };
              } else {
                break;
              }
            case 'isRevisited':
              if (this.patientInfo[item]) {
                return {
                  label: DICTIONARIES[item],
                  value: this.patientInfo[item] === true ? '是' : '否',
                };
              } else {
                break;
              }

            default:
              if (this.patientInfo[item]) {
                return {
                  label: DICTIONARIES[item],
                  value: this.patientInfo[item],
                };
              }
          }
        })
        .filter(function (s) {
          return s && s;
        });
    },
  },
};
</script>

<style lang="scss">
h3,
h4,
h5 {
  margin: 0 auto;
}
.addORreduce {
  width: 100%;
  height: 25px;
  background: #f6f6f6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 4px;
  box-sizing: border-box;
  i {
    display: flex;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
  }
  .reduce {
    &::before {
      display: block;
      content: '';
      width: 7px;
      height: 1px;
      background-color: #a9a9a9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .add {
    &::before {
      display: block;
      content: '';
      width: 7px;
      height: 1px;
      background-color: #a9a9a9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      display: block;
      content: '';
      width: 7px;
      height: 1px;
      background-color: #a9a9a9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
.handlerWraper {
  height: calc(100vh - 60px);
  overflow: scroll;
  width: 100%;
  .el-collapse-item__header {
    padding-left: 18px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #4a4a4a;
  }
  .el-textarea__inner {
    height: 96px;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    width: 200px;
  }

  .iframe {
    width: 100%;
    height: 300px;
  }

  .box {
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 10px 20px 0px #ecf2f2;
    border-radius: 6px 6px 6px 6px;
    margin: 10px 10px 16px 10px;
    h3 {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4a4a4a;
      line-height: 40px;
      text-indent: 18px;
      background: #fbfbfb;
    }
  }
  .dataList {
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
    }
    li {
      width: 32%;
      list-style: none;
      font-size: 14px;
      line-height: 40px;
      display: flex;
      .fileName {
        color: #666;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .userInfo {
    // height: 234px;

    .boxCard {
      width: 100%;
      ul {
        // display: flex;
        flex-wrap: wrap;
        padding: 10px;
      }
      li {
        list-style: none;
        font-size: 14px;
        line-height: 28px;
        margin-right: 3px;
        display: flex;
        cursor: pointer;
        .label {
          color: #666;
          font-weight: bold;
          width: 120px;
        }
        span {
          color: #666;
          display: block;
        }
      }
    }
  }

  .judgeForm {
    font {
      font-size: 14px;
      display: block;
    }
    height: 100%;
    padding: 22px 10px 10px;
    box-sizing: border-box;
    overflow: auto;
    :global {
      .el-form-item {
        margin-bottom: 10px !important;
      }
      .el-select {
        width: 100%;
      }
    }
  }

  .searchBox {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    padding-left: 22px;
    height: 40px;
    margin-top: 14px;
    margin-bottom: 11px;
  }

  .flexBox {
    margin-bottom: 10px !important;
    :global {
      .el-form-item__content {
        margin-left: 0px !important;
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px auto 100px;
    a {
      background: #31b4b0;
      border-radius: 4px;
      color: #fff;
      display: block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid #dcdfe6;
      color: #606266;
      -webkit-appearance: none;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      -webkit-transition: 0.1s;
      transition: 0.1s;
      font-weight: 500;
      padding: 12px 20px;
      font-size: 14px;
      border-radius: 4px;
      text-decoration-line: none;
      color: #fff;
    }
  }
}
</style>
