<template>
  <div>
    <div class="textDocWrap">
      <div>
        <Im />
      </div>
      <div class="warp-info">
        <ForDiagnose />
      </div>
    </div>
  </div>
</template>

<script>
import Im from './textComponents/Im.vue';
import ForDiagnose from './components/ForDiagnose';
import { initIm } from '@/utils/tim';
import { mapState } from 'vuex';
import { loginDoctorDetail, queryConsultationOrderIMList } from './api/doctor';

export default {
  components: {
    Im,
    ForDiagnose,
  },
  data() {
    return {
      firstInit: true,
      TIM: null,
      loginInfo: {},
    };
  },
  watch: {
    conversationList: {
      handler(newValue, oldValue) {
        this.addListParams(newValue, oldValue);
      },

      //   deep: true,
      // immediate: true,
    },
    isSDKReady(val) {
      console.log('isSDKReady', val);
      if (val) {
        this.initRtc();
      }
    },
  },
  computed: {
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
      diagnosisList: (state) => {
        return state.conversation.diagnosisList;
      },
      conversationList: (state) => {
        return state.conversation.conversationList;
      },
      custom: (state) => state.patient.custom,
      isSDKReady: (state) => state.user.isSDKReady,
    }),
  },

  async created() {
    this.$bus.on('changeOrderStatus', this.changeOrderStatus);
    this.logIn();
  },
  mounted() {},

  beforeDestroy() {
    this.$bus.off('changeOrderStatus', this.changeOrderStatus);
  },

  methods: {
    async addListParams(newValue, oldValue) {
      // 如果sessionlist 的长度变化 就获取最新d订单列表 进行比较
      console.log('问诊室监听会话列表', newValue, oldValue, this.firstInit);
      // if (!newValue.length || this.firstInit) return;

      // const data = newValue[0]['lastMessage']['payload']['data'];
      // if (data && data.includes('videoCall')) {
      //   return;
      // }
      await this.getOrderList();
    },
    async changeOrderStatus(type) {
      console.log(type, 'changeOrderStatusUpdateCustom');
      await this.getOrderList();

      if (type == 'accept') {
        //切换问诊状态
        this.$store.dispatch('updateCustom');
      } else if (type == 'refuse' || type == 'complete') {
        // await this.$store.dispatch(
        //   'deleteConversation',
        //   this.currentConversation.conversationID
        // );

        this.$store.dispatch('updateCustom', {
          reset: true,
        });
        this.$store.commit('resetCurrentConversation');
      } else if (type == 'refreshAll') {
        console.log('刷新全部订单列表');
      } else {
        console.log('未知bus');
      }
    },
    async getOrderList() {
      const res = await queryConsultationOrderIMList({});
      // let list = [];
      if (res.data.code != 0) {
        return this.$message.error(res.data.msg);
      }
      this.$store.commit('updateDiagnosisList', res.data.data);
    },
    async initRtc() {
      const { doctorImToken, doctorIm, doctorImSdkAppId } = this.loginInfo;

      this.trtcCalling.login({
        sdkAppID: Number(doctorImSdkAppId),
        userID: doctorIm,
        userSig: doctorImToken,
      });
    },
    async logIn() {
      const res = await loginDoctorDetail();

      const { doctorImToken, doctorIm, doctorImSdkAppId } = res.data.data;
      this.loginInfo = {
        doctorImToken,
        doctorIm,
        doctorImSdkAppId,
      };
      sessionStorage.setItem(
        'loginDoctorDetail',
        JSON.stringify(res.data.data)
      );
      if (!window.$tim) {
        await initIm(Number(doctorImSdkAppId));
        await $tim.login({
          userID: doctorIm, //  用户ID
          userSig: doctorImToken, //用户签名
        });
      }

      this.$store.dispatch('updateIminfo', {
        userID: doctorIm,
        userSig: doctorImToken,
      });

      setTimeout(async () => {
        await this.getOrderList();

        this.TIM = $tim;
        const promise = await $tim.getConversationList();
        // this.$store.dispatch('deleteConversation', 'C2C1635191112000335873');

        const { data = {} } = promise;

        let conversationList = data.conversationList.filter(
          (i) => i.type == 'C2C'
        );

        if (this.diagnosisList.length == 0) {
          this.$store.commit('updateConversationList', []);
          this.firstInit = false;
          return;
        }

        this.diagnosisList.length &&
          conversationList.forEach((i) => {
            const item = this.diagnosisList.find(
              (ii) => 'C2C' + ii.patientIm === i.conversationID
            );

            if (item) {
              i.orderNo = item.orderNo;
              i.patientName = item.patientName;
              i.consultationType = item.consultationType;
              i.consultationOrderNo = item.consultationOrderNo;
              i.orderStatus = item.orderStatus;
            }
          });

        conversationList = conversationList.filter((i) => i.orderNo);

        this.$store.commit('updateConversationList', conversationList);
        this.firstInit = false;
        if (conversationList && conversationList.length) {
          // 系统消息不渲染

          this.$store.dispatch(
            'checkoutConversation',
            conversationList[0]['conversationID']
          );

          this.$store.commit('UPDATE_HANDEL_ITEM', conversationList[0]);
          this.$store.dispatch('updateCustom');
        }
      }, 800);
    },
  },
};
</script>
<style lang="scss">
body {
  background: #f1f4f5;
}

.textDocWrap {
  display: flex;

  box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.38);

  & > div {
    width: 70%;
  }
  .warp-info {
    width: 30%;
  }
}

.flexBox {
  display: flex;
  flex-flow: wrap;
  position: relative;
}
</style>
